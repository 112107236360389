export enum MatomoEventType {
  trackEvent = "trackEvent",
  trackLink = "trackLink",
  trackSiteSearch = "trackSiteSearch",
}

interface BaseEvent {
  type: MatomoEventType;
}

interface TrackEvent extends BaseEvent {
  type: MatomoEventType.trackEvent;
  category: string;
  action: string;
  name?: string;
  value?: number;
  callback?: () => any;
  customData?: any;
}

interface TrackLink extends BaseEvent {
  type: MatomoEventType.trackLink;
  sourceUrl: string;
  linkType: string;
  customData?: any;
  callback?: () => any;
}

interface TrackSiteSearch extends BaseEvent {
  type: MatomoEventType.trackSiteSearch;
  keyword: string;
  category?: string | false;
  resultsCount?: number;
  customData?: any;
}

type MatomoEvents = TrackEvent | TrackLink | TrackSiteSearch;

function mapEventToOrderedProperties(event: MatomoEvents): any[] {
  switch (event.type) {
    case MatomoEventType.trackEvent:
      return [event.type, event.category, event.action, event.name, event.value, event.customData, event.callback];
    case MatomoEventType.trackLink:
      return [event.type, event.sourceUrl, event.linkType, event.customData, event.callback];
    case MatomoEventType.trackSiteSearch:
      return [event.type, event.keyword, event.category, event.resultsCount, event.customData];
    default:
      console.error("There is no event defined for this type.", (event as any).type);
      return [];
  }
}

export function sendMatomoEvent(event: MatomoEvents): void {
  window._paq = window._paq || [];

  const properties = mapEventToOrderedProperties(event);

  window._paq.push(properties);
}
